import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"

const AboutUs = () => (
  <Layout>
    <Meta title="About Us Fresh peeled and sliced apple products | Greenville Apple Products" />
    <div className="container pad">
    <article className="prose gray">
      <h1>About Us</h1>
      <p>We are a local family ran business since 1954. We run year round and offer a superior product through out New England and New York. We love what we do and take pride In our apples! We offer all natural kosher certified non gmo preservatives on all our apple products to keep healthy and fresh. We also offer no preservatives.</p> 
      <p>Our products consist of peeled and cored sliced , diced , apple rings, apple dumplings.</p>
      <p>Or maybe you just want a delicious whole apple we sell by the bushel.</p>
      <p>We provide our products to bakeries, restaurants, hotels, wholesale bakeries, food distributors, nursing homes, colleges, and other companies that use fresh ingredients.</p>
    </article>
    </div>
  </Layout>
)

export default AboutUs
